import { useRef, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import RouterComponent from './router/Router';
import { Analytics } from '@vercel/analytics/react';
import './App.css';


function App() {
  const cursor = useRef(null);
  const changePosition = (e: any) => {
    if (cursor.current) {
      const cursorElement: any = cursor.current;
      cursorElement.style.top = `${e.clientY}px`;
      cursorElement.style.left = `${e.clientX}px`;
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', changePosition);
    return () => {
      document.removeEventListener('mousemove', changePosition);
    };
  }, []);
  return (
    <div
      className='app'
    >
      <div className='cursor' ref={cursor} ></div>
      <BrowserRouter>
        <RouterComponent />
      </BrowserRouter>
      <Analytics />
    </div>
  )
}

export default App;
