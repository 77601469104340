import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import MainPage from '../pages/MainPage';
import Gallery from '../pages/Gallery';

const RouterComponent: React.FC = () => {
  return (
    <Routes>
        <Route path="/" element={<MainPage/>} />
        <Route path="/gallery" element={<Gallery/>} />
        <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};

export default RouterComponent;
