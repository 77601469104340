import React from 'react';
import './style.css';
import Card from '../common/Card';

interface project {
  title: string;
  githubLink: string;
  projectLink: string
  description?: string;
  time: string;
  tags: string[];
}

const Project: React.FC = () => {
  const projectsList: project[] = [
    {
      title: 'Pokemon-App',
      githubLink: 'https://github.com/HrithikVaishnav/Pokemon-data',
      projectLink: 'https://pokemon-data.vercel.app/',
      description: `A web-based react app for searching and finding data of a Pokemon.
      Fetching data from API and showing it to the user. Learned about react components and hooks
      through this project.
      `,
      time: 'March, 2021',
      tags: ['ReactJS'],
    },
    {
      title: 'V-Blog',
      githubLink: 'https://github.com/HrithikVaishnav/VBlog',
      projectLink: '',
      description: `A web based application for reading and writing blogs.
      A user can log in, update login details and read blogs of other users and write blogs.
      `,
      time: 'July, 2020',
      tags: ['NodeJs', 'HTML', 'CSS' , 'Ejs-template'],
    },
    {
      title: 'V-Reaction',
      githubLink: 'https://github.com/HrithikVaishnav/V-Reaction',
      projectLink: 'https://striped-color.surge.sh/',
      description: `A web based color picker game application.`,
      time: 'December, 2020',
      tags: ['JavaScript'],
    },
  ];

  return (
    <Card 
      cardArr={projectsList}
      title={'Projects'}
      compId={'projects'}
    />
  );
};

export default Project;
