import React from 'react';
import '../pages/style.css';
import Chip from '@mui/material/Chip';
import { FaGithub } from 'react-icons/fa';
import { GrLinkUp } from "react-icons/gr";
import { HeaderText } from '../pages/MainPage';

interface CardInterface {
    title?: string;
    githubLink?: string;
    projectLink?: string
    description?: string;
    time?: string;
    tags?: string[];
    link?: string;
    role?: string;
    location?: string;
    result?: string;
}

interface Iprops {
    cardArr: CardInterface[],
    title: string,
    compId: string,
}

const Card: React.FC<Iprops> = (props) => {
    const { cardArr, title, compId } = props;

    return (
        <div className='aboutMeBody whiteColorText'>

            <HeaderText text={title}/>

            <div id={compId} className='companyCards'>
                {
                    (cardArr || []).map((card, index) => (
                        <div className='expCompanyCard' key={index}>

                            <div style={{
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <div className='companyName'>
                                    {card.title}
                                    {
                                        card.projectLink ? 
                                            <span className='linkStyle'>
                                                <a href={card.projectLink} target='_blank' rel='noopener noreferrer'>
                                                    <GrLinkUp className='rotateIcon icon' />
                                                </a>
                                            </span>
                                        : 
                                            <span className='linkStyle'>
                                                <a href={card.link} target='_blank' rel='noopener noreferrer'>
                                                    <GrLinkUp className='rotateIcon icon' />
                                                </a>
                                            </span>
                                    }
                                    {
                                        card.githubLink ? 
                                            <span className='linkStyle'>
                                                <a href={card.githubLink} target='_blank' rel='noopener noreferrer'>
                                                    <FaGithub className='icon' />
                                                </a>
                                            </span>:
                                        null
                                    }
                                </div>
                                <div className='companyPeriod'>{card.time}</div>
                            </div>

                            <div className='companyRole'>{card.role}</div>

                            
                            <div className='companyLocation'>{card.location}</div>

                            <div className='companyDesc'>{card.description}</div>
                            
                            <div className='companyPeriod'>{card.result}</div>
                            <div className='chipStyle'>
                            {
                                (card?.tags || []).map(item => <Chip 
                                    label={item} 
                                    variant="outlined" 
                                    className='chipStyle' 
                                    style={{ backgroundColor: '#292929' }} 
                                />)
                            }
                            </div>
                            
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Card;
