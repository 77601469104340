import React from 'react';
import './style.css';
import Card from '../common/Card';

interface experience {
  title: string;
  link: string;
  role: string;
  description?: string;
  location: string;
  time: string;
  tags: string[];
}

const Experience: React.FC = () => {
  const expList: experience[] = [
    {
      title: 'Shipsy',
      link: 'https://shipsy.io/',
      role: 'Software Engineer',
      description: `I connected our system with other shipping companies to do more things like booking and 
      tracking orders, which helped us bring in over 10 new clients. I made it easier for us to 
      get information from certain places, which doubled the number of things our customers sent 
      using that method. I also made our system better by creating warnings if something 
      wasn’t working right and by making a tool that makes things faster for customers 
      to know what's happening.`,
      location: 'Gurgaon, India',
      time: 'July, 2022 - Current',
      tags: ['NodeJS', 'JavaScript', 'Python', 'ReactJS', 'pSQL', 'MongoDB', 'Redis'],
    },
    {
      title: 'Shipsy',
      link: 'https://shipsy.io/',
      role: 'Software Engineer Intern',
      location: 'Remote',
      description: `I focused on integrating new shipping partners (3PLs) and SMS vendors. 
      Specifically, I handled integrations with FTP/sFTP connections, and built a 
      data transfer system to move information from Postgres to Google Cloud Storage (GCS).`,
      time: 'January, 2022 - June, 2022',
      tags: ['NodeJS', 'JavaScript', 'Python', 'ReactJS', 'pSQL', 'MongoDB', 'sFTP', 'GCP'],
    },
    {
      title: 'Cashbook',
      link: 'https://cashbook.in/',
      role: 'Software Developer Intern',
      description: `I worked on CashBook app, an Android application utilized by more than 
      500K+ users. My role involved enhancing the user interface (UI), fixing bugs, and 
      integrating a user-friendly filter and entries section directly onto the Home screen 
      for easier access and navigation.`,
      location: 'Remote',
      time: 'May, 2021 - July, 2021',
      tags: ['React-Native', 'Firebase'],
    },
    {
      title: 'Codebugged AI',
      link: 'https://codebugged.com/',
      role: 'Software Research Engineering Intern',
      description: `Improved a video calling application by optimizing its performance through WebRTC. Specifically,
      contributed to implementing a SFU using media soup and pion library.`,
      location: 'Remote',
      time: 'May, 2021 - July, 2021',
      tags: ['JavaScript', 'WebRTC', 'SFU'],
    },
  ];

  return (
    <Card 
      cardArr={expList}
      title={'Experience'}
      compId={'experience'}
    />
  );
};

export default Experience;
