import React from 'react';
import './style.css';

const Gallery: React.FC = () => {

  return (
    <div className='aboutMeBody whiteColorText'>
        
    </div>
  );
};

export default Gallery;
