import React from 'react';
import './style.css';
import Card from '../common/Card';

interface project {
  title: string;
  link: string;
  result?: string;
  time: string;
  tags: string[];
}

const Education: React.FC = () => {
  const projectsList: project[] = [
    {
      title: 'IIIT Vadodara',
      link: 'https://iiitvadodara.ac.in/',
      result: '8.32 cgpa',
      time: 'August, 2018 - May, 2022',
      tags: [],
    },
    {
        title: 'Jawahar Navodayan Vidyalaya, K.City, Nagaur',
        link: 'https://navodaya.gov.in/nvs/nvs-school/NAGAUR/en/home/',
        result: '86.80 in 12th',
        time: '2010-2017',
        tags: [],
    },
  ];

  return (
    <Card 
      cardArr={projectsList}
      title={'Education'}
      compId={'education'}
    />
  );
};

export default Education;
