import './style.css';
import Typed from 'typed.js';
import { useEffect, useRef, useState } from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { MdEmail } from "react-icons/md";
import Experience from './Experience';
import Project from './Project';
import Education from './Education';

const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
};

const AboutMe = () => {
    const el = useRef(null);
    const [isActive, setIsActive] = useState('aboutMe');
    const emailAddress = 'hrithikv777@gmail.com';

    useEffect(() => {
        const typed = new Typed(el.current, {
            strings: ['Learning...', 'Building...', 'Growing...'],
            startDelay: 300,
            typeSpeed: 100,
            backSpeed: 100,
            backDelay: 100,
            smartBackspace: true,
            loop: true,
            showCursor: true,
            cursorChar: '!'
        });

        // Destropying
        return () => {
        typed.destroy();
        };
    }, []);

    return (
        <div className='AboutMe'>
            <div className='AboutMeLeft'>
                <div className='introSection'>
                    <div>
                        <p className='pageHeading'>
                            Hrithik Vaishnav
                        </p> 
                    </div>
                    <div className='h2Style'>
                        <h2>Software Engineer</h2>
                    </div>
                    <div className='writeupSection'>
                        <span ref={el}></span>
                    </div>
                </div>

                <div className='menuSection'>
                    <div className={isActive === 'aboutMe' ? 'menuItem activeMenu' : 'menuItem'} onClick={() => {
                        scrollToSection('aboutMe');
                        setIsActive('aboutMe');
                    }}>
                        <span className='horizontalLine'></span>
                        <span className='menuItemText'>About Me</span> 
                    </div>
                    <div className={isActive === 'experience' ? 'menuItem activeMenu' : 'menuItem'} onClick={() => {
                        scrollToSection('experience');
                        setIsActive('experience');
                    }}>
                        <span className='horizontalLine'></span> 
                        <span className='menuItemText'>Experience</span> 
                    </div>
                    <div className={isActive === 'projects' ? 'menuItem activeMenu' : 'menuItem'} onClick={() => {
                        scrollToSection('projects');
                        setIsActive('projects');
                    }}>
                        <span className='horizontalLine'></span> 
                        <span className='menuItemText'>Projects</span> 
                    </div>
                    <div className={isActive === 'education' ? 'menuItem activeMenu' : 'menuItem'} onClick={() => {
                        scrollToSection('education');
                        setIsActive('education');
                    }}>
                        <span className='horizontalLine'></span> 
                        <span className='menuItemText'>Education</span> 
                    </div>
                </div>
                <div>
                    <MidSection 
                        title='Hire me, My resume!'
                        url='https://drive.google.com/file/d/1hK1QU_lT2UYoydZujZNW204cB6mNwAcH/view?usp=sharing'
                    />
                </div>
            </div>
            <div className='AboutMeRight'>
                <div className='iconContainer'>
                    <a href='https://github.com/HrithikVaishnav/' target='_blank' rel='noopener noreferrer'>
                        <FaGithub className='icon margin10' />
                    </a>
                </div>
                <div className='iconContainer'>
                    <a href='https://www.linkedin.com/in/hrithik-vaishnav-6174271b9/' target='_blank' rel='noopener noreferrer'>
                        <FaLinkedin className='icon margin10' />
                    </a>
                </div>
                <div className='iconContainer'>
                    <a href='https://twitter.com/hrithikv001' target='_blank' rel='noopener noreferrer'>
                        <FaSquareXTwitter className='icon margin10' />
                    </a>
                </div>
                <div className='iconContainer'>
                    <a href={`mailto:${emailAddress}`} target='_blank' rel='noopener noreferrer'>
                        <MdEmail className='icon margin10' />
                    </a>
                </div>
            </div>
        </div>
    )
}

export const HeaderText = (props: any) => {
    const { text } = props;
    return (
        <div className='headerBar'>
                {text}
        </div>
    )
}


const Description = () => {
    return (
        <div>
            <HeaderText text='About Me'/>
            <section id='aboutMe' className='companyCards whiteColorText'
            style={{
                margin: 20
            }}>
                <p>
                    Hello there! I'm <span className='specialText'>Hrithik Vaishnav</span>, 
                    a Software engineer based in India and a proud CS graduate from 
                    <span className='specialText'>IIIT Vadodara</span>.
                </p>
                <p>
                    Outside the coding realm, you'll often catch me engrossed in movies, 
                    exploring the beautiful landscapes, or engaging in a friendly 
                    game of <span className='specialText'>cricket</span>or   
                    <span className='specialText'>badminton</span>.
                </p>
            </section>
        </div>
    )
}

const MidSection = (props: any) => {
    const { title, url} = props;
    return (
        <div className='myResume'>
            <div className='statusLive'>
                <div className='greenCircle'></div>
            </div>
            <div>
                <a 
                    href={url}
                    target='_blank' 
                    rel='noopener noreferrer'
                    style={{
                        color: '#03C988',
                        position: 'relative',
                        padding: 10,
                        fontSize: 15,
                        letterSpacing: .5
                    }}  
                >
                    <p>{title}</p>
                </a>
            </div>
        </div>
    )
}

const MainPage = () => {
    return (
        <div className='homeBody'>
            <div className='homeBodyLeft'>
                <AboutMe />
            </div>
            <div className='homeBodyRight'>
                <div>
                    <Description />
                </div>
                <div>
                    <Experience />
                </div>
                <div className='sectionSeparator'></div>
                <div>
                    <MidSection 
                        title='My Travelling Blogs!'
                        url='https://hrithikv.blogspot.com/'
                    />
                </div>
                <div>
                    <Project />
                </div>
                <div>
                    <MidSection 
                        title='Explore All Projects!'
                        url='https://github.com/HrithikVaishnav/'
                    />
                </div>
                <div className='sectionSeparator'></div>
                <div>
                    <Education />
                </div>
                <div className='sectionSeparator'></div>
            </div>
        </div>
    )
}

export default MainPage;
